import React from "react";
import "./Home.css";
import sites from "../sites.json";
import Header from "../Components/Header";
function App() {
  return (
    <div className="MainApp">
      <div className="container">
        <div className="row align-items-center">
          <Header />
          <h4 style={{ marginTop: 30, marginBottom: 40 }}>SİTELER</h4>
          <div className="row">
            {sites.map(
              (site) =>
                site.url && ( // URL varsa render edilir
                  <a
                    href={site.url}
                    key={site.name}
                    className="sites col-4 col-md-2 col-lg-1 mx-4"
                    style={{ color: "white", textDecoration: "none" }}

                  >
                    <div
                      className="siteLogoContainer"
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={site.logo} alt={site.name} />
                    </div>
                    
                      <h5 className="site-name">{site.name}</h5>
                  </a>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
