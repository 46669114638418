export default function Header() {
  return (
    <>
      <div className="logo col-md-2 col-sm-7 col-8 mx-auto mx-md-0 text-center text-md-start">
        <img src="./cadoftp.png" class="img-fluid" alt="Logo" />
      </div>
      {/* Navbar Bölümü */}
      <div className="col-md-10 text-center">
        <nav className="navbar navbar-expand-md">
          {/* Toggle button for mobile view */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/hizmetler">
                  HİZMETLERİMİZ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  SİTELERİMİZ
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
