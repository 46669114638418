import React from "react";
import "./Home.css";
import sites from "../sites.json";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
function App() {
  return (
    <div className="MainApp">
      <div className="container">
        <div className="row align-items-center">
          <Header />

          <div className="row" style={{ marginTop: 100 }}>
            <div className="col-sm box mx-2">
              <div class="card cardstyle1">
                <div class="card-header">
                  <h4>AFFILATE</h4>
                </div>
                <div
                  id="cardCarousel"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner" style={{ borderRadius: 5 }}>
                    <div class="carousel-item active">
                      <img
                        src="https://images.ctfassets.net/ukazlt65o6hl/60tydjpPAGY5JYmuu9Y3XL/8bcd6c77a91c87b289490fbe921bf827/Banner_image.png?w=1920&h=873&q=70&fm=webp"
                        class="d-block w-100"
                        alt="Slider Resim 2"
                      />
                    </div>

                    <div class="carousel-item">
                      <img
                        src="https://www.searchenginejournal.com/wp-content/uploads/2021/09/16-reasons-why-social-media-is-important-to-your-company-616d3200e6dc6-sej-1280x720.png"
                        class="d-block w-100"
                        alt="Slider Resim 1"
                      />
                    </div>
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#cardCarousel"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Önceki</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#cardCarousel"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Sonraki</span>
                  </button>
                </div>
                <div class="card-body">
                  <h5>Hizmetlerimiz</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-video"></i> &nbsp; Sosyal Medya
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-users"></i> &nbsp;Fenomenler
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-brands fa-twitter"></i> &nbsp; Twitter
                    </li>
                    <li class="list-group-item">
                      <i class="fa-brands fa-telegram"></i> &nbsp; Telegram
                    </li>
                    <li class="list-group-item">
                      <i class="fa-brands fa-instagram"></i>
                      &nbsp; Instagram
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm box mx-2">
              <div class="card cardstyle1">
                <div class="card-header">
                  <h4>REKLAM</h4>
                </div>
                <div
                  id="cardCarousel"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner" style={{ borderRadius: 5 }}>
                    <div class="carousel-item active">
                      <img
                        src="https://image.adsoftheworld.com/static/aotw-meta.jpg"
                        class="d-block w-100"
                        alt="Slider Resim 2"
                      />
                    </div>
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#cardCarousel"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Önceki</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#cardCarousel"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Sonraki</span>
                  </button>
                </div>
                <div class="card-body">
                  <h5>Hizmetlerimiz</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-brands fa-google"></i> &nbsp; Google Ads
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-brands fa-meta"></i> &nbsp;Meta Ads
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm box mx-2">
              <div class="card cardstyle1">
                <div class="card-header">
                  <h4>YAZILIM</h4>
                </div>
                <div
                  id="cardCarousel"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner" style={{ borderRadius: 5 }}>
                    <div class="carousel-item active">
                      <img
                        src="https://bairesdev.mo.cloudinary.net/blog/2023/08/Software-Development-Models.jpg?tx=w_1728,q_auto"
                        class="d-block w-100"
                        alt="Slider Resim 2"
                      />
                    </div>
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#cardCarousel"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Önceki</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#cardCarousel"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Sonraki</span>
                  </button>
                </div>
                <div class="card-body">
                  <h5>Hizmetlerimiz</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-money-check-dollar"></i> &nbsp;
                      Ödeme Paneli
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-business-time"></i> &nbsp; ERP
                      Paneli
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-search"></i> &nbsp; SEO
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-pen-fancy"></i> &nbsp; UI/IX Design
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <i class="fa-solid fa-money-code"></i> &nbsp; Backend
                      Solutions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-20" style={{ marginTop: 100 }}>
            <h4>SİTELER</h4>
            <marquee
              style={{ marginTop: 15 }}
              direction="left"
              onmouseover="stop()"
              onmouseout="start()"
            >
              <div className="site-slider">
                {sites.map(
                  (site) =>
                    site.url && ( // URL varsa render edilir
                      <a
                        href={site.url}
                        key={site.name}
                        className="sites col-4 col-md-2 col-lg-1 mx-4"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <div
                          className="siteLogoContainer"
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={site.logo} alt={site.name} />
                        </div>
                        <h5 className="site-name">{site.name}</h5>
                      </a>
                    )
                )}
              </div>
            </marquee>
          </div>

          <div className="row mx-20" style={{ marginTop: 100 }}>
            <h4>BİZE ULAŞIN</h4>
            <div className="col-sm contact">
              <fa className="fa-brands fa-skype" style={{ fontSize: 100 }}></fa>
              <fa
                className="fa-brands fa-telegram"
                style={{ fontSize: 100, marginLeft: 40 }}
              ></fa>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
